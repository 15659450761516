var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', {
    staticClass: "justify-content-center"
  }, [_c('b-col', {
    attrs: {
      "md": "10",
      "lg": "8",
      "sm": "12"
    }
  }, [_c('b-card', [_c('b-row', {
    staticClass: "mb-4"
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('h4', {}, [_c('strong', [_vm._v(" Detail Transaksi ")])])])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "9",
      "sm": "12"
    }
  }, [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "No. Invoice"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.detailTransaksi ? _vm.detailTransaksi.no_invoice : ""))])])], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tanggal Transaksi"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.detailTransaksi ? _vm.getDateTime(_vm.detailTransaksi.tanggal) : "-"))])])], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Status Pembayaran"
    }
  }, [_vm.detailTransaksi && _vm.detailTransaksi.status_bayar == 'lunas' ? _c('b-badge', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(" LUNAS ")]) : _c('b-badge', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v(" BELUM LUNAS ")]), _vm.detailTransaksi && _vm.isFinance && _vm.detailTransaksi.status_bayar == 'belum_lunas' ? _c('b-button', {
    staticClass: "ml-1",
    attrs: {
      "variant": "outline-warning",
      "size": "sm"
    },
    on: {
      "click": _vm.modalEditPembayaran
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "EditIcon"
    }
  })], 1) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Status Pengiriman"
    }
  }, [_vm.detailTransaksi && _vm.detailTransaksi.status == 'selesai' ? _c('b-badge', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(" SELESAI ")]) : _vm.detailTransaksi && _vm.detailTransaksi.status == 'diproses' ? _c('b-badge', {
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v(" DIPROSES ")]) : _vm.detailTransaksi && _vm.detailTransaksi.status == 'dikirim' ? _c('b-badge', {
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" DIKIRIM ")]) : _vm.detailTransaksi && _vm.detailTransaksi.status == 'diterima' ? _c('b-badge', {
    attrs: {
      "variant": "info"
    }
  }, [_vm._v(" DITERIMA ")]) : _vm.detailTransaksi && _vm.detailTransaksi.status == 'dibatalkan' ? _c('b-badge', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v(" DIBATALKAN ")]) : _c('b-badge', {
    attrs: {
      "variant": "secondary"
    }
  }, [_vm._v(" PENDING ")]), _vm.isCabang && _vm.detailTransaksi.status == 'diproses' || _vm.detailTransaksi.status == 'dikirim' ? _c('b-button', {
    staticClass: "ml-1",
    attrs: {
      "variant": "outline-warning",
      "size": "sm"
    },
    on: {
      "click": _vm.modalEditPengiriman
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "EditIcon"
    }
  })], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1), _vm.detailTransaksi.status == 'diproses' || _vm.detailTransaksi.status == 'pending' ? _c('b-button', {
    staticClass: "mt-3",
    attrs: {
      "variant": "gradient-danger"
    },
    on: {
      "click": _vm.batalkanPesanan
    }
  }, [_vm._v("Batalkan Pesanan")]) : _vm._e()], 1), _c('b-card', [_c('h4', {}, [_c('strong', [_vm._v(" Status Pemesanan ")])]), _c('div', {
    staticClass: "order-status"
  }, _vm._l(_vm.orderStatus, function (status, index) {
    return _c('div', {
      key: index,
      staticClass: "order-status-item",
      class: {
        active: status.active,
        done: status.done
      }
    }, [_c('div', {
      staticClass: "iconPemesanan"
    }, [_c('feather-icon', {
      attrs: {
        "icon": status.icon
      }
    })], 1), _c('div', {
      staticClass: "text"
    }, [_vm._v(_vm._s(status.text))])]);
  }), 0)]), _c('b-modal', {
    attrs: {
      "centered": "",
      "id": "editPembayaran",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "hide-footer": "",
      "title": "Konfirmasi Pembayaran"
    },
    model: {
      value: _vm.showKonfirmasi,
      callback: function callback($$v) {
        _vm.showKonfirmasi = $$v;
      },
      expression: "showKonfirmasi"
    }
  }, [_c('validation-observer', {
    ref: "vkonfirmasi"
  }, [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jenis Transaksi"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": [{
        value: 1,
        text: 'Debit'
      }, {
        value: 2,
        text: 'Kredit'
      }],
      "id": "v-transaction_type",
      "name": "transaction_type"
    },
    model: {
      value: _vm.transactionType,
      callback: function callback($$v) {
        _vm.transactionType = $$v;
      },
      expression: "transactionType"
    }
  })], 1)], 1), _vm.transactionType ? _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Pilih Akun"
    }
  }, [_c('validation-provider', {
    attrs: {
      "rules": "required",
      "name": "id_akun"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-select', {
          attrs: {
            "options": _vm.id_akun,
            "id": "v-id_akun",
            "name": "id_akun"
          },
          model: {
            value: _vm.formkonfirmasi.id_akun,
            callback: function callback($$v) {
              _vm.$set(_vm.formkonfirmasi, "id_akun", $$v);
            },
            expression: "formkonfirmasi.id_akun"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 4095445891)
  })], 1)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Pilih Kas"
    }
  }, [_c('validation-provider', {
    attrs: {
      "rules": "required",
      "name": "id_kas"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-select', {
          attrs: {
            "options": _vm.id_kas,
            "id": "v-id_kas",
            "name": "id_kas"
          },
          model: {
            value: _vm.formkonfirmasi.id_kas,
            callback: function callback($$v) {
              _vm.$set(_vm.formkonfirmasi, "id_kas", $$v);
            },
            expression: "formkonfirmasi.id_kas"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1), _c('b-button', {
    attrs: {
      "variant": "primary",
      "block": ""
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.submitkonfirmasi($event);
      }
    }
  }, [_vm._v(" Konfirmasi ")])], 1), _c('b-modal', {
    attrs: {
      "centered": "",
      "id": "editPengiriman",
      "title": "Ubah Status Pengiriman",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.simpanPengiriman($event);
            }
          }
        }, [_vm._v("Simpan")])];
      },
      proxy: true
    }])
  }, [_c('b-form-group', {
    attrs: {
      "label": "Status"
    }
  }, [_c('v-select', {
    attrs: {
      "options": _vm.optionPengiriman,
      "label": "text"
    },
    model: {
      value: _vm.pengiriman_status,
      callback: function callback($$v) {
        _vm.pengiriman_status = $$v;
      },
      expression: "pengiriman_status"
    }
  })], 1), _vm.no_resi_show ? _c('b-form-group', {
    attrs: {
      "label": "No. Resi"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.no_resi,
      callback: function callback($$v) {
        _vm.no_resi = $$v;
      },
      expression: "no_resi"
    }
  })], 1) : _vm._e()], 1), _c('b-modal', {
    attrs: {
      "id": "cancel-form",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.doCancel($event);
            }
          }
        }, [_vm._v("Simpan")])];
      },
      proxy: true
    }])
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tulis alasan anda membatalkan transaksi"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.cancelForm.keterangan,
      callback: function callback($$v) {
        _vm.$set(_vm.cancelForm, "keterangan", $$v);
      },
      expression: "cancelForm.keterangan"
    }
  })], 1)], 1), _vm.detailTransaksi.bukti_tf && _vm.getExtension(_vm.detailTransaksi.bukti_tf.file) != 'pdf' ? _c('b-card', [_c('b-card-header', [_vm._v(" Bukti Bayar")]), _c('b-card-body', [_c('center', [_vm.getExtension(_vm.detailTransaksi.bukti_tf.file) == 'png' || _vm.getExtension(_vm.detailTransaksi.bukti_tf.file) == 'jpg' ? _c('img', {
    attrs: {
      "src": _vm.apiFile + _vm.detailTransaksi.bukti_tf.file,
      "alt": "",
      "width": "300px"
    }
  }) : _vm._e(), _vm.getExtension(_vm.detailTransaksi.bukti_tf.file) == 'pdf' ? _c('iframe', {
    attrs: {
      "src": _vm.apiFile + _vm.detailTransaksi.bukti_tf.file
    }
  }) : _vm._e()])], 1)], 1) : _vm._e()], 1)], 1), _vm.detailTransaksi ? _c('div', {
    staticClass: "card",
    attrs: {
      "id": "printableArea"
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('center', [_c('img', {
    staticClass: "mb-4",
    attrs: {
      "src": require("../../../../public/assets/mybisnis-sm.png"),
      "alt": "",
      "width": "200px"
    }
  })]), _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('h3', [_c('b', [_vm._v("INVOICE")])]), _c('h3', [_c('b', [_vm._v("Kode Referral : " + _vm._s(_vm.detailTransaksi.konsumen_info ? _vm.detailTransaksi.konsumen_info.kode_referral : "SQ000"))])])]), _c('span', {
    staticClass: "text--primary h6"
  }, [_c('b', [_vm._v(_vm._s(_vm.detailTransaksi.no_invoice))])]), _c('div', {
    staticClass: "row mt-3"
  }, [_c('div', {
    staticClass: "col-sm"
  }, [_c('h5', [_c('b', [_vm._v("DITERBITKAN ATAS NAMA")])]), _c('table', [_c('tr', [_c('td', [_vm._v(" Penjual : "), _c('b', [_vm._v(_vm._s(_vm.user.karyawan.nama_lengkap))])])]), _c('tr', [_c('td', [_vm._v(" Alamat : "), _c('b', [_vm._v(_vm._s(_vm.myGudang.alamat))])])])])]), _c('div', {
    staticClass: "col-sm"
  }, [_c('h5', [_c('b', [_vm._v("PEMESAN")])]), _c('table', [_c('tr', [_c('td', {
    attrs: {
      "width": "200px"
    }
  }, [_vm._v("Pembeli")]), _c('td', [_c('b', [_vm._v(" " + _vm._s(_vm.detailTransaksi && _vm.detailTransaksi.konsumen_info ? _vm.detailTransaksi.konsumen_info.nama_konsumen : "-"))])])]), _c('tr', [_c('td', [_vm._v("No. Telepon")]), _c('td', [_c('b', [_vm._v(_vm._s(_vm.detailTransaksi && _vm.detailTransaksi.konsumen_info ? _vm.detailTransaksi.konsumen_info.no_hp_konsumen : "-"))])])]), _c('tr', [_c('td', {
    staticStyle: {
      "display": "flex"
    }
  }, [_vm._v("Alamat Pembelian")])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_c('b', [_vm._v(_vm._s(_vm.detailTransaksi && _vm.detailTransaksi.konsumen_info && _vm.detailTransaksi.konsumen_info.pengiriman ? _vm.detailTransaksi.konsumen_info.pengiriman.alamat_pengiriman : "-"))]), _vm._v(", " + _vm._s(_vm.detailTransaksi && _vm.detailTransaksi.konsumen_info && _vm.detailTransaksi.konsumen_info.pengiriman ? _vm.detailTransaksi.konsumen_info.pengiriman.kelurahan : "-") + ", " + _vm._s(_vm.detailTransaksi && _vm.detailTransaksi.konsumen_info && _vm.detailTransaksi.konsumen_info.pengiriman ? _vm.detailTransaksi.konsumen_info.pengiriman.kecamatan : "-") + ", " + _vm._s(_vm.detailTransaksi && _vm.detailTransaksi.konsumen_info && _vm.detailTransaksi.konsumen_info.pengiriman ? _vm.detailTransaksi.konsumen_info.pengiriman.kab_kota : "-") + ", ")])])])])]), _c('div', {
    staticClass: "table-responsive mt-5"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("INFO PRODUK")]), _c('th', [_vm._v("JUMLAH")]), _c('th', [_vm._v("HARGA BARANG")]), _c('th', [_vm._v("DISKON")]), _c('th', [_vm._v("TOTAL HARGA")])])]), _c('tbody', _vm._l(_vm.detailTransaksi.rincian, function (item, i) {
    return _c('tr', {
      key: i
    }, [_c('td', [_c('div', {
      staticClass: "text--primary"
    }, [_vm._v(_vm._s(item.nama_barang))])]), _c('td', [_vm._v(_vm._s(item.qty))]), _c('td', [_vm._v("Rp." + _vm._s(_vm.formatRupiah(item.harga_jual)))]), _c('td', [_vm._v("Rp." + _vm._s(_vm.formatRupiah(item.diskon_rp)))]), _c('td', [_vm._v("Rp." + _vm._s(_vm.formatRupiah(item.total_harga)))])]);
  }), 0)])]), _c('div', {
    staticClass: "row mt-3"
  }, [_c('hr'), _c('div', {
    staticClass: "col-sm-6"
  }), _c('div', {
    staticClass: "col-sm"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_c('b', [_vm._v("Biaya Lainnya")])]), _c('div', [_c('b', [_vm._v(_vm._s(_vm.formatRupiah(_vm.detailTransaksi.biaya_lainnya ? _vm.detailTransaksi.biaya_lainnya : 0)))])])]), _c('hr'), _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_c('b', [_vm._v("Ongkos Kirim")])]), _c('div', [_c('b', [_vm._v(_vm._s(_vm.formatRupiah(_vm.detailTransaksi.ongkir ? _vm.detailTransaksi.ongkir : 0)))])])]), _c('hr'), _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_c('b', [_vm._v("Total")])]), _c('div', [_c('b', [_vm._v(_vm._s(_vm.formatRupiah(_vm.detailTransaksi.rincian_biaya ? _vm.detailTransaksi.rincian_biaya.total_bayar : 0)))])])]), _c('hr'), _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_c('b', [_vm._v("Uang Muka")])]), _c('div', [_c('b', [_vm._v(_vm._s(_vm.formatRupiah(_vm.detailTransaksi.rincian_biaya ? _vm.detailTransaksi.rincian_biaya.uang_masuk : 0)))])])]), _c('hr'), _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_c('b', [_vm._v("Sisa")])]), _c('div', [_c('b', [_vm._v(_vm._s(_vm.formatRupiah(_vm.detailTransaksi.rincian_biaya ? _vm.detailTransaksi.rincian_biaya.total_kekurangan : 0)))])])])])]), _c('hr'), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-6"
  }, [_vm._v(" PENGIRIMAN : " + _vm._s(_vm.getDate(_vm.detailTransaksi.konsumen_info.tanggal_pengambilan)) + " "), _c('hr'), _c('table', [_c('tr', [_c('h5', [_c('b-form-checkbox', {
    staticClass: "mr-1",
    attrs: {
      "inline": "",
      "value": "diambil"
    },
    model: {
      value: _vm.detailTransaksi.konsumen_info.opsi_pengambilan,
      callback: function callback($$v) {
        _vm.$set(_vm.detailTransaksi.konsumen_info, "opsi_pengambilan", $$v);
      },
      expression: "detailTransaksi.konsumen_info.opsi_pengambilan"
    }
  }), _vm._v(" Diambil Konsumen ")], 1)]), _c('tr', [_c('h5', [_c('b-form-checkbox', {
    staticClass: "mr-1",
    attrs: {
      "inline": "",
      "value": "dikirim"
    },
    model: {
      value: _vm.detailTransaksi.konsumen_info.opsi_pengambilan,
      callback: function callback($$v) {
        _vm.$set(_vm.detailTransaksi.konsumen_info, "opsi_pengambilan", $$v);
      },
      expression: "detailTransaksi.konsumen_info.opsi_pengambilan"
    }
  }), _vm._v(" Dikirim ")], 1)])]), _c('h5', [_c('b', [_vm._v("PENERIMA")])]), _c('table', [_c('tr', [_c('td', {
    attrs: {
      "width": "200px"
    }
  }, [_vm._v("Penerima")]), _c('td', [_c('b', [_vm._v(" " + _vm._s(_vm.detailTransaksi && _vm.detailTransaksi.konsumen_info ? _vm.detailTransaksi.konsumen_info.nama_penerima : "-"))])])]), _c('tr', [_c('td', [_vm._v("No. Telepon")]), _c('td', [_c('b', [_vm._v(_vm._s(_vm.detailTransaksi.konsumen_info.no_hp_penerima ? _vm.detailTransaksi.konsumen_info.no_hp_penerima : "-"))])])]), _c('tr', [_c('td', {
    staticStyle: {
      "display": "flex"
    }
  }, [_vm._v("Alamat Pembelian")])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_c('b', [_vm._v(_vm._s(_vm.detailTransaksi && _vm.detailTransaksi.konsumen_info && _vm.detailTransaksi.konsumen_info.pengiriman ? _vm.detailTransaksi.konsumen_info.pengiriman.alamat_pengiriman : "-"))]), _vm._v(", " + _vm._s(_vm.detailTransaksi && _vm.detailTransaksi.konsumen_info && _vm.detailTransaksi.konsumen_info.pengiriman ? _vm.detailTransaksi.konsumen_info.pengiriman.kelurahan : "-") + ", " + _vm._s(_vm.detailTransaksi && _vm.detailTransaksi.konsumen_info && _vm.detailTransaksi.konsumen_info.pengiriman ? _vm.detailTransaksi.konsumen_info.pengiriman.kecamatan : "-") + ", " + _vm._s(_vm.detailTransaksi && _vm.detailTransaksi.konsumen_info && _vm.detailTransaksi.konsumen_info.pengiriman ? _vm.detailTransaksi.konsumen_info.pengiriman.kab_kota : "-") + " ")])])])]), _c('div', {
    staticClass: "col-sm-6"
  }, [_vm._v(" Kurir : "), _c('h6', [_c('b', [_vm._v("-")])])])])], 1)]) : _vm._e(), _c('b-button', {
    staticClass: "text-center w-100",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.printContent
    }
  }, [_vm._v("Print")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }